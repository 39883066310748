<template>
  <div class="tickets-list">
    <div class="list-title mem-subtitle">
      {{ $t("tickets") }}
    </div>
    <div class="list-header">
      <div>
        <div v-if="showAlertMessage" class="alert-assign">
          <img :src="require('@/assets/warning_fire.svg')" alt="alert" />
          <div>{{ $t("assign_tickets_to_racers") }}</div>
        </div>
      </div>
      <div class="list-column">
        {{ $t("type") }}
      </div>
      <div class="list-column">
        {{ $t("start_time") }}
      </div>
    </div>
    <ticket
      v-for="(ticket, index) in ticketsList"
      :key="index"
      :ticket="ticket"
      :eventDate="eventData.start_date"
      :ticket-index="index + 1"
      :select-mode="selectMode"
      @select="onSelect"
      @sendTicket="sendTicketDialog = true"
      @removeTeam="removeTeamDialog = true"
    />
    <div class="list-buttons" v-if="canSelectTeam">
      <mem-button
        v-if="!selectMode"
        btn-type="secondary-dark"
        :disabled="availableTeamTickets.length === 0"
        @click="enableSelectMode"
        >{{ $t("run_as_a_team") }}</mem-button
      >

      <div v-if="selectMode" class="select-mode">
        <mem-button @click="selectMode = false" btn-type="tertiary-dark">{{
          $t("cancel")
        }}</mem-button>
        <mem-button
          btn-type="secondary-dark"
          :disabled="selectedTickets.length === 0"
          @click="showAddTeamDialog"
          >{{ $t("create_or_join_team") }}</mem-button
        >
      </div>
    </div>

    <!-- modals -->
    <!-- send ticket -->
    <v-dialog
      class="mem-dialog"
      light
      overlay-color="#000000"
      overlay-opacity="0.54"
      :max-width="480"
      v-model="sendTicketDialog"
    >
      <send-ticket-dialog @onClose="sendTicketDialog = false" />
    </v-dialog>
    <!-- create or join team -->
    <v-dialog
      class="mem-dialog"
      light
      overlay-color="#000000"
      overlay-opacity="0.54"
      :max-width="400"
      v-model="addTeamDialog"
    >
      <join-team-dialog
        v-if="joinTeamView"
        :tickets="selectedTickets"
        @onCreateTeam="switchTeamDialog"
        @onClose="closeAddTeamDialog"
      />

      <create-team-dialog
        v-if="createTeamView"
        :tickets="selectedTickets"
        @onCancel="switchTeamDialog"
        @onClose="closeAddTeamDialog"
      />
    </v-dialog>
    <!-- remove ticket team -->
    <v-dialog
      class="mem-dialog"
      light
      overlay-color="#000000"
      overlay-opacity="0.54"
      :max-width="338"
      v-model="removeTeamDialog"
    >
      <leave-team-dialog @onClose="removeTeamDialog = false" />
    </v-dialog>
  </div>
</template>
<script>
import { find, filter, map, lowerCase } from "lodash";
import { mapActions } from "vuex";
import { TS_CHECKOUT_COUNTRIES } from "@/common/const";
export default {
  components: {
    ticket: () => import("./TicketsListItem.vue"),
    "send-ticket-dialog": () => import("./DialogSendTicket.vue"),
    "join-team-dialog": () => import("./DialogJoinTeam.vue"),
    "create-team-dialog": () => import("./DialogCreateTeam.vue"),
    "leave-team-dialog": () => import("./DialogLeaveTeam.vue"),
    "mem-button": () => import("@/components/base/BaseButton.vue"),
  },
  data: () => ({
    selectMode: false,
    selectedTickets: [],
    //
    sendTicketDialog: false,
    //
    addTeamDialog: false,
    joinTeamView: false,
    createTeamView: false,
    //
    removeTeamDialog: false,
  }),
  props: {
    tickets: {
      type: Array,
      default: () => [],
    },
    eventData: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    showAlertMessage() {
      let { is_passed } = this.eventData;

      if (is_passed) return false;

      return find(this.ticketsList, (ticket) => {
        let { is_claimed, is_purchased_by_me, is_mine, is_kids } = ticket;
        return (
          (!is_claimed && is_purchased_by_me && is_mine && !is_kids) ||
          (!is_claimed && is_purchased_by_me && is_kids)
        );
      });
    },
    canSelectTeam() {
      // const SUPPORTED_COUNTRIES = ["US", "MX", "EU", "CH"];
      let { is_passed, ts_org } = this.eventData;

      if (is_passed) return;
      if (this.availableTeamTickets.length === 0) return;

      return TS_CHECKOUT_COUNTRIES.includes(lowerCase(ts_org));
    },
    ticketsList() {
      let {
        id,
        is_passed,
        is_started,
        ticket_addons_currency,
        spartan_tab_price_before,
        photo_package_price_before,
      } = this.eventData;

      return this.tickets.map((ticket) => {
        return {
          selected: this.selectedTickets.includes(ticket.barcode),
          event: {
            id,
            is_passed,
            is_started,
            // addons
            ticket_addons_currency,
            spartan_tab_price_before,
            photo_package_price_before,
          },
          ...ticket,
        };
      });
    },
    availableTeamTickets() {
      let barcodesList = map(
        filter(this.ticketsList, (ticket) => {
          return !ticket.team && ticket.status === "Purchased";
        }),
        "barcode"
      );
      return barcodesList;
    },
  },
  methods: {
    ...mapActions(["updateEventTickets"]),
    onSelect(ticket) {
      if (!ticket.selected) return this.selectedTickets.push(ticket.barcode);

      let index = this.selectedTickets.indexOf(ticket.barcode);
      this.selectedTickets.splice(index, 1);
    },
    enableSelectMode() {
      this.selectedTickets = this.availableTeamTickets;
      this.selectMode = !this.selectMode;
    },

    // add team dialog
    showAddTeamDialog() {
      this.addTeamDialog = true;
      this.joinTeamView = true;
    },
    closeAddTeamDialog() {
      this.addTeamDialog = false;
      this.selectMode = false;
      this.joinTeamView = false;
      this.createTeamView = false;
    },
    switchTeamDialog() {
      this.joinTeamView = !this.joinTeamView;
      this.createTeamView = !this.createTeamView;
    },
  },
  channels: {
    AthleteUserTicketsChannel: {
      connected() {
        console.log("Account::AthleteUserTicketsChannel connected");
      },
      rejected() {
        console.log("Account::AthleteUserTicketsChannel rejected");
      },
      received(payload) {
        console.log("%cWS message", "color: green", "Message received");
        console.log(JSON.parse(payload));
        console.log("-----");
        if (payload) {
          let data = JSON.parse(payload);
          this.updateEventTickets(data);
        }
      },
      disconnected() {
        console.log("Account::AthleteUserTicketsChannel disconnected");
      },
    },
  },
  created() {
    this.$cable.subscribe(
      { channel: "Account::AthleteUserTicketsChannel" },
      "AthleteUserTicketsChannel"
    );
  },
  mounted() {},
};
</script>
    
<style lang="scss" scoped>
.tickets-list {
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  padding-bottom: 50px;
  @media screen and (max-width: $tablet) {
    padding-top: 37px;
  }
  .list-title {
    @include elementMarginBottom("S");
  }
  .list-header {
    --card-layout: 64px;
    display: grid;
    grid-template-columns: calc(330px + var(--card-layout)) 120px 100px;
    // padding: 16px 0;
    padding-bottom: 16px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    letter-spacing: 0.0025em;
    text-transform: uppercase;
    @media screen and (max-width: $tablet) {
      grid-template-columns: 1fr;
    }
    @media screen and (max-width: $mobile) {
      padding-left: 20px;
    }
    .alert-assign {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #ec5858;
      img {
        margin-right: 8px;
      }
    }
    .list-column {
      color: #9d9d9d;
      @media screen and (max-width: $tablet) {
        display: none;
      }
      &:last-child {
        text-align: end;
      }
    }
  }
  .list-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 22px;

    button {
      height: 36px;
      padding: 0 36px;
      font-size: 14px;
      line-height: 17px;
      font-weight: 700;
      letter-spacing: 0.04em;
    }
    .select-mode {
      @media screen and (max-width: $tablet) {
        display: flex;
        flex-direction: column;
      }
      button:first-child {
        @media screen and (max-width: $tablet) {
          order: 2;
        }
      }
      button:last-child {
        margin-left: 15px;
        @media screen and (max-width: $tablet) {
          margin-left: 0;
          margin-bottom: 16px;
          order: 1;
        }
      }
    }
  }
}
</style>